export const TheWall = () => {
    const bgStyle = {
		// backgroundColor:"#000",
		// height:"900px",
		backgroundSize: "100% 100%",
		backgroundRepeat: "no-repeat",
		backgroundImage: "url('./assets/img/Twitter banner.png')"
    };
    
    
    return (
        <section id="thewall">
            <div class="wrapper">
                <div id="preview">
                    <div class="card">
                        <div class="card_part card_part-one"></div>
                        <div class="card_part card_part-two"></div>
                        <div class="card_part card_part-three"></div>
                        <div class="card_part card_part-four"></div>
                        <div class="card_part card_part-five"></div>
                        <div class="card_part card_part-six"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}