import LazyLoad from 'react-lazyload';

export const Hero = () => {
    const bgStyle = {
		// backgroundColor:"#000",
		// height:"900px",
		backgroundSize: "100% 100%",
		backgroundRepeat: "no-repeat",
		backgroundImage: "url('./assets/img/Twitter banner.png')"
    };

    return (
        <section id="hero" style={bgStyle}>
            <div class="wrapper-wide">
                <div class="wrapper">
                    {/* <LazyLoad>
                        <img id="user-big" src="./assets/img/road_map_new_1.png"  />
                    </LazyLoad> */}
                    <p class="wrapper-narrow">
                        {/* XXXXXXXXXXXXXXXXXXXXXXXXXXX */}
                        {/* Hypebeasts. Fashion killers. Trendhoppers. Whatever you call them, their looks are crisp clean. Rich Cycle Rebels came here to bring style to the Ethereum blockchain. */}
                    </p>
                    <a id="join-discord" class="zoom" title="Buy on Opensea" href="#">Buy on Opensea</a>
                </div>
            </div>
        </section>
    )
}