import LazyLoad from 'react-lazyload';

export const About = () => {
    return (
        <section id="about">
            <div class="wrapper sidekick">
                <h2 class="glitch" data-text="What is RICH CYCLE REBELS?"> What is RICH CYCLE REBELS?</h2>
                <p>
                    {/* XXXXXXXXXX */}
                    A collection of 7,777 meta fashion avatars based on the Ethereum blockchain. Each unique RICH CYCLE REBELS is randomly generated from combination of over 200 individually drawn elements, including over 100 different outfits.
                </p>
                <p>Launch <mark>28.01.2022</mark></p>
                <p>Supply <mark>7,777 NFTs</mark></p>
                <p>Price <mark>0.07 ETH</mark></p>
                <p>Presale buyer limit <mark>2 Per Wallet</mark></p>
                <p>Mainsale buyer limit <mark>7 Per Transaction</mark></p>
            </div>
        </section>
    )
}