export const RodMap = () => {
    return (
        <section id="roadmap">
            <div class="wrapper">
                <img id="spaceman" src="./assets/img/road_map_new_1.png"  />
                <h2 class="glitch" data-text="Roadmap">Roadmap</h2>
                <div id="roadmap-intro">
                   <p> RICH CYCLE REBELS NFT was created to be the highest rewarding NFT collection for the
                    community. With continued giveaways, games, announcements and more…
                    </p>
                    <p>
                    RICH CYCLE REBELS is an NFT for the people. Here are our Phases:
                    </p>
                    {/* Your token grants you access to RICH CYCLE REBELS metaverse, post-sale deliverables and most importantly -- a sense of community. RICH CYCLE REBELS was created out of love for art, fashion, technology and passion for intellectual property in the form of NFT. Our primary goal is to push the boundaries and empower our holders as we progress.  */}
                </div>
                <div id="road-1">
                    <h3 id="pre" class="glitch" data-text="PHASE 1:">PHASE 1:</h3>
                    <h4 id="post_new" class="glitch" data-text="IN THE BEGINNING: Staking">IN THE BEGINNING: Staking</h4>
                    <p class="">
                        <ul>
                            <li>
                            Users will be able to stake their NFTs and receive an ERC20 Token what we will call EKS
Token
                            </li>
                            <li>
                            EKS stands for Experience, Knowledge and Skills
                            </li>
                        </ul>
                    </p>
                    <h3 id="pre" class="glitch" data-text="PHASE 2:">PHASE 2:</h3>
                    <h3 id="post_new" class="glitch" data-text="New drops to start our 3D Projects">New drops to start our 3D Projects</h3>
                </div>
                <div id="road-2">
                    <p class="">
                        <span id="p10"></span>
                        <ul>
                            <li>
                                3D Avatars
                            </li>
                        </ul>
                    </p>
                    <p class="">
                        <span id="p20"></span>
                        <ul>
                            <li>
                                Mind blowing special 3D collection
                            </li>
                        </ul>
                    </p>
                </div>
                <div id="road-3">
                    <h3 id="pre" class="glitch" data-text="PHASE 3:">PHASE 3:</h3>
                    <h3 id="post_new" class="glitch" data-text="Launch pre_orders on EKS collection
clothing line">Launch pre_orders on EKS collection
clothing line</h3>
                    <p>
                        <ul>
                            <li>
                            Clothing collabs
                            </li>
                            <li>
                            Rich Cycle Couture
                            </li>
                            {/* <li>
                            The runway to fame
                            </li> */}
                        </ul>
                    </p>
                </div>
                <div id="road-3">
                    <h3 id="pre" class="glitch" data-text="PHASE 4:">PHASE 4:</h3>
                    <h3 id="post_new" class="glitch" data-text="Launch short animation video for the avatars created">Launch short animation video for the avatars created</h3>
                </div>
                <div id="road-1">
                    <h3 id="pre" class="glitch" data-text="PHASE 5:">PHASE 5:</h3>
                    <h4 id="post_new" class="glitch" data-text="Create Rich Cycle NFT & Fashion academy">Create Rich Cycle NFT & Fashion academy</h4>
                    <p class="">
                        This will be the first NFT Academy worldwide; DON'T MISS IT
                    </p>
                    <h3 id="pre" class="glitch" data-text="PHASE 6:">PHASE 6:</h3>
                    <h3 id="post_new">Create a MMORPG ( Massive Multi Online Role Player Game) game with all avatars combined</h3>
                    <p class="">
                        Buying Land and entering one of the Metaverses (NFT Holders will vote on which Metaverse)
                    </p>
                </div>
            </div>
        </section>
    )
}