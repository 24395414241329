export const Header = () => {
    return (
        <header>
            <img id="top-logo" src="./assets/img/Rich Cycle Logo.png" width="100px" height="81px" />
            <ul id="top-menu">
                <li><a aria-current="page" href="/#roadmap" class="router-link-active router-link-exact-active">Roadmap</a></li>
                <li><a aria-current="page" href="/#team" class="router-link-active router-link-exact-active">Team</a></li>
                {/* <li><a href="/provenance" class="">Provenance</a></li> */}
                <li><a class="mini-icon" target="_blank" title="Discord" href="#"><img src="./assets/img/icon-discord.198b4b9d.svg" /></a></li>
                <li><a class="mini-icon" target="_blank" title="Twitter" href="#"><img src="./assets/img/icon-twitter.f550eb84.svg" /></a></li>
                <li><a class="mini-icon" target="_blank" title="OpenSea" href="#"><img src="./assets/img/icon-opensea.64cfdb51.svg" /></a></li>
                <li><a id="connect-wallet" title="Connect Wallet" href="#">Connect Wallet</a></li>
            </ul>
        </header>
    )
}