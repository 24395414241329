import logo from './logo.svg';
// import './App.css';
import { Header } from './components/Header';
import { Hero } from './components/Hero';
import { About } from './components/About';
import { TheWall } from './components/TheWall';
import { RodMap } from './components/RodMap';
import { Team } from './components/Team';
import { Footer } from './components/Footer';
import { Rewards } from './components/Rewards';

function App() {
  return (
    // <div className="App">
    <>
      <Header />
      <Hero />
      <About />
      <TheWall />
      <Rewards />
      <RodMap />
      <Team />
      <Footer />
    </>
    // </div>
  );
}

export default App;
