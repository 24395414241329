export const Footer = () => {
    return(
        <footer>
            <div id="footer-yellow">
                <div class="wrapper">
                    <p>JOIN THE REBELS</p>
                    <br/>
                    <ul id="socials">
                        <li>
                            <a class="zoom" target="_blank" title="Discord" href="#">
                                <img src="./assets/img/icon-discord.198b4b9d.svg" />
                            </a>
                        </li>
                        <li>
                            <a class="zoom" target="_blank" title="Twitter" href="#">
                                <img src="./assets/img/icon-twitter.f550eb84.svg" />
                            </a>
                        </li>
                        <li>
                            <a class="zoom" target="_blank" title="OpenSea" href="#">
                                <img src="./assets/img/icon-opensea.64cfdb51.svg" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="footer-red">
                <div class="wrapper"> © 2021 RICH CYCLE REBELS | 
                    <a title="View contract on Etherscan" href="#">View contract on Etherscan</a>
                </div>
            </div>
        </footer>
    )
}