export const Rewards = () => {
    return (
        <section id="roadmap">
            <div class="wrapper">
                <img id="spaceman" src="./assets/img/team_4.png"  />
                <h2 class="gtch" data-text="SALES AND REWARDS MILESTONES">SALES AND REWARDS MILESTONES</h2>
                <div id="roadmap-intro">
                </div>
                {/* <h3 id="post">SALES MILESTONES</h3> */}
                <div id="road-2">
                    <p class="">
                        <span id="p10">10% Sold</span>
                        <>
                            <>
                                10 RICH CYCLE REBELS NFTs will be airdropped to our early adopters
                            </>
                            <>
                                sting on rarity tools
                            </>
                        </>
                    </p>
                    <p class="">
                        <span id="p20">20% Sold</span>
                        <>
                            <>
                                20 RICH CYCLE REBELS NFTs Holders Giveaway
                            </>
                            <>
                                $10000 Eth Giveaway for 2 NFT Holders ($5k in ETH)
                            </>
                        </>
                    </p>
                    <p class="">
                        <span id="p40">30% Sold</span> 
                        <>
                            <>
                                Donating 1 Meal with each NFT Sold (30% from 7777)
                            </>
                            <>
                                Meals will be distributed across the Network of NYC Fridges
                            </>
                        </>
                    </p>
                    <p class="">
                        <span id="p60">40% Sold</span> 
                        <>
                            <>
                                A winner (1st place) of a rich cycle design contest will be picked by the community to
                            </>
                            <>
                                create a rebel NFT collection in collaboration with rich cycle
                            </>
                        </>
                    </p>
                    <p class="">
                        <span id="p80">60% Sold</span> 
                        <>
                            <>
                                $20000 Eth giveaway for the most active 4 users in Discord ($5k in ETH)
                            </>
                        </>
                    </p>
                    <p class=""> 
                        <span id="p100">80% Sold</span> 
                        <>
                            <>                    
                                10% of all transactions will go back to holders to reward them for holding Rich Cycle
                            </>
                        </>
                    </p>
                    <p class="">
                        <span id="p40">100% Sold</span>
                        <>
                            <> 
                                After we sell out, every NFT holder will get a Metaverse Wearable airdropped to their Wallet
                            </>
                            <>
                            Donating
7777 Meals to NYC community fridges
                            </>
                            <>
                            After 30 days of the main
launch all holders will receive a special invite to buy the Upcoming 3D collection. This invite
will allow you to purchase the 3D collection on mint day for 25% less than the pubc mint
price
                            </>
                            <>
                            After 100% sold,
roadmap milestone PHASE 1 begins
                            </>
                        </>
                    </p>
                </div>
            </div>
        </section>
    )
}