export const Team = () => {
    return(
        <section id="team">
            <div class="wrapper">
                <h2 class="glitch" data-text="Team behind project">Team behind project</h2>
                <div id="teammates">
                    <div class="teammate">
                        <a target="_blank" title="Boris Raw" href="https://twitter.com/retroyohi">
                            <img src="./assets/img/team.png" />
                        </a>
                        <h3 id="post_new" class="glitch" data-text="Kashif">Kashif (CEO)</h3>
                        <div class="row">
                            <div class="column">
                                <a href="https://www.linkedin.com/feed/" target="_blank">
                                    <img src="./assets/img/in.png" />
                                </a>
                            </div>
                            <div class="column">
                                <a href="https://www.facebook.com/login/" target="_blank">
                                    <img src="./assets/img/fb.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="teammate" >
                        <a target="_blank" title="Boris Raw" href="https://twitter.com/retroyohi">
                            <img src="./assets/img/team.png" />
                        </a>
                        <h3 id="post_new" class="glitch" data-text="Kashif">Kashif (CEO)</h3>
                        <div class="row">
                            <div class="column">
                                <a href="https://www.linkedin.com/feed/" target="_blank">
                                    <img src="./assets/img/in.png" />
                                </a>
                            </div>
                            <div class="column">
                                <a href="https://www.facebook.com/login/" target="_blank">
                                    <img src="./assets/img/fb.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="teammates">
                    <div class="teammate">
                        <a target="_blank" title="Kashif" href="https://twitter.com/ratvsratvsrat">
                            <img src="./assets/img/team_1.png"/>
                        </a>
                        <h3 id="post_new" class="glitch" data-text="Kashif">Kashif (Designer)</h3>
                        <div class="row">
                            <div class="column">
                                <a href="https://www.linkedin.com/feed/" target="_blank">
                                    <img src="./assets/img/in.png" />
                                </a>
                            </div>
                            <div class="column">
                                <a href="https://www.facebook.com/login/" target="_blank">
                                    <img src="./assets/img/fb.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="teammate">
                        <a  target="_blank" title="Kashif" href="https://twitter.com/CryptoSciart">
                            <img src="./assets/img/team_2.png"/>
                        </a>
                        <h3 id="post_new" class="glitch" data-text="Kashif">Kashif (Artist)</h3>
                        <div class="row">
                            <div class="column">
                                <a href="https://www.linkedin.com/feed/" target="_blank">
                                    <img src="./assets/img/in.png" />
                                </a>
                            </div>
                            <div class="column">
                                <a href="https://www.facebook.com/login/" target="_blank">
                                    <img src="./assets/img/fb.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="teammate">
                        <a target="_blank" title="Kashif" href="https://instagram.com/notpetrovichvk">
                            <img src="./assets/img/team_3.png" />
                        </a>
                        <h3 id="post_new" class="glitch" data-text="Kashif">Kashif (Advisor)</h3>
                        <div class="row">
                            <div class="column">
                                <a href="https://www.linkedin.com/feed/" target="_blank">
                                    <img src="./assets/img/in.png" />
                                </a>
                            </div>
                            <div class="column">
                                <a href="https://www.facebook.com/login/" target="_blank">
                                    <img src="./assets/img/fb.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="teammate">
                        <a target="_blank" title="Albert" href="#">
                            <img src="./assets/img/team_4.png" />
                        </a>
                        <h3 id="post_new" class="glitch" data-text="Kashif">Kashif (Designer)</h3>
                        <div class="row">
                            <div class="column">
                                <a href="https://www.linkedin.com/feed/" target="_blank">
                                    <a href="https://www.linkedin.com/feed/" target="_blank">
                                    <img src="./assets/img/in.png" />
                                </a>
                                </a>
                            </div>
                            <div class="column">
                                <a href="https://www.facebook.com/login/" target="_blank">
                                    <a href="https://www.facebook.com/login/" target="_blank">
                                    <img src="./assets/img/fb.png" />
                                </a>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}